import { config } from "../config";
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory();
import { authHeader } from "../authHeader";

export const userService = {
    login,
    logout,
    register,
    validatePhone,
    getAll,
    getSingle,
    sendEvaluation,
    getAvatar,
    setUserLocation,
    updateUserSettings,
    verifyPassword,
    changePassword,
    getStats,
    getHistory,
};

async function login(passport) {
    const requestOptions = {
        method: "POST",
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        dataType: "json",
        body: JSON.stringify(passport),
    };

    return fetch(`${config.env}/user/signin`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            const passport = {
                ...user,
            };
            passport.password = undefined;
            // TODO: potentially an option
            localStorage.setItem("user", JSON.stringify(passport));
            return passport;
        });
}

function logout() {
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("language", lang);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     history.push("/");
            //     window.location.reload();
            //

            const error = { message: data || response.statusText, status: response.status };

            return Promise.reject(error);
        }

        return data;
    });
}

async function register(user) {
    const requestOptions = {
        method: "POST",
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        dataType: "json",
        body: JSON.stringify(user),
    };

    return fetch(`${config.env}/user/register`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            localStorage.setItem("user", JSON.stringify({ login: true }));
            return data;
        });
}

async function validatePhone(mobile) {
    return true;
}

async function getAll() {
    const requestOptions = {
        method: "GET",
    };

    return fetch(
        `${config.env}/office/getuserlist?recordsPerPage=50&currentPage=1`,
        requestOptions
    ).then(handleResponse);
}

async function getSingle(id) {
    const requestOptions = {
        method: "GET",
    };

    return fetch(`${config.env}/office/GetUserDetails?avatarId=${id}`, requestOptions).then(
        handleResponse
    );
}

async function sendEvaluation(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        dataType: "json",
        body: JSON.stringify(data),
    };
    return fetch(`${config.env}/evaluation/CreateEvaluation`, requestOptions).then(handleResponse);
}

async function getAvatar(id) {
    const requestOptions = {
        method: "GET",
    };
    return fetch(`${config.env}/user/getavatar?avatarid=${id}`, requestOptions).then(
        handleResponse
    );
}

async function setUserLocation(location) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        dataType: "json",
        body: JSON.stringify(location),
    };
    return fetch(`${config.env}/user/SetAvatarLocationEx`, requestOptions).then(handleResponse);
}

async function updateUserSettings(settings) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        dataType: "json",
        body: JSON.stringify(settings),
    };
    return fetch(`${config.env}/user/UpdateUserSettings`, requestOptions).then(handleResponse);
}

async function verifyPassword(passport) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        dataType: "json",
        body: JSON.stringify(passport),
    };
    return fetch(`${config.env}/user/VerifiyPassword`, requestOptions).then(handleResponse);
}

async function changePassword(passport) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        dataType: "json",
        body: JSON.stringify(passport),
    };
    return fetch(`${config.env}/user/ChangePassword`, requestOptions).then(handleResponse);
}

async function getStats(avatarId) {
    const requestOptions = {
        method: "GET",
    };
    return fetch(
        `${config.env}/lead/ListStatisticsForLeads?avatarId=${avatarId}`,
        requestOptions
    ).then(handleResponse);
}

async function getHistory(avatarId) {
    const requestOptions = {
        method: "GET",
    };
    return fetch(
        `${config.env}/lead/ListHistoryAsCandidate?avatarId=${avatarId}`,
        requestOptions
    ).then(handleResponse);
}
