import { miscConstants } from "../constants/misc.constants";

export function misc(state = {}, action) {
    switch (action.type) {
        case miscConstants.SHOW_POPUP:
            return {
                showPopup: true,
                popupReason: action.reason,
                data: action.data,
            };
        case miscConstants.HIDE_POPUP:
            return {
                showPopup: false,
            };
        case miscConstants.CLEAR:
            return {};
        default:
            return state;
    }
}
