import React from "react";
import NoticePopupInner from "./NoticePopupInner";
import { useSelector } from "react-redux";

const NoticePopup = () => {
    const reason = useSelector((state) => state.misc.popupReason);

    return (
        <div className={"notice-popup-wrapper" + (reason === "updateCGU" ? " no-background" : "")}>
            <NoticePopupInner />
        </div>
    );
};

export default NoticePopup;
