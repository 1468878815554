import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyC930sngio7-YHrCLUnRy2Zxgnv4Fc9Njw",
    authDomain: "af-apk1-firebase.firebaseapp.com",
    databaseURL: "https://af-apk1-firebase.firebaseio.com",
    projectId: "af-apk1-firebase",
    storageBucket: "af-apk1-firebase.appspot.com",
    messagingSenderId: "169145841048",
    appId: "1:169145841048:web:fa86769fa51103c4eca7ed",
    measurementId: "G-Y4P66WGV68",
};

const vapidKey =
    "BOZlCMR20kJjke436bi8gO5u4Li2_muYpmdhnVkfuB3-XfeZDIXopld5y6xUGYKQ0cSlY0gN8ShTuH7Pbuds_Rk";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const getToken = (setToken) => {
    return messaging
        .getToken({ vapidKey })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                setToken(currentToken);

                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log("No registration token available. Request permission to generate one.");
                setToken(null);
                // shows on the UI that permission is required
            }
        })
        .catch(
            (err) => console.log("An error occurred while retrieving token. ", err)
            // catch error while creating client token
        );
};

export const onMessageListener = () =>
    new Promise((resolve) => messaging.onMessage((payload) => resolve(payload)));
