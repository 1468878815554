import React from "react";
import "../AppHome/main-style.scss";

const Loader = () => {
    return (
        <div className="loader">
            <h4 className="display-4">Chargement</h4>
            <span className="spinner-border spinner-border-sm"></span>
        </div>
    );
};

export default Loader;
