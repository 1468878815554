import React, { Suspense, lazy, useState, useEffect } from "react";
import { Route, Switch } from "react-router";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { onMessageListener } from "./firebase";
import { miscActions } from "./helpers/actions/misc.actions";
import { userActions } from "./helpers/actions/user.actions";
// import { notificationActions } from "./helpers/actions/notification.actions";

import Loader from "./components/Loader/Loader";
import NoticePopup from "./components/AppHome/NoticePopup/NoticePopup";
const Login = lazy(() => import("./components/Auth/Login/Login.jsx"));
const Register = lazy(() => import("./components/Auth/Register/Register.jsx"));
const ForgotPassword = lazy(() => import("./components/Auth/ForgotPassword/ForgotPassword"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute/PrivateRoute.jsx"));
const MapPage = lazy(() => import("./components/AppHome/Pages/MapPage"));
const StatsPage = lazy(() => import("./components/AppHome/Pages/StatsPage"));
const OfferPage = lazy(() => import("./components/AppHome/Pages/OfferPage"));
const MapListItemPage = lazy(() => import("./components/AppHome/Pages/MapListItemPage"));
const HistoryPage = lazy(() => import("./components/AppHome/Pages/HistoryPage"));
const NotificationsPage = lazy(() => import("./components/AppHome/Pages/NotificationsPage"));
const SettingsPage = lazy(() => import("./components/AppHome/Pages/SettingsPage"));
const SettingsPrefPage = lazy(() => import("./components/AppHome/Pages/SettingsPrefPage"));
const SettingsSecurityPage = lazy(() => import("./components/AppHome/Pages/SettingsSecurityPage"));
const SettingsConditionsPage = lazy(() =>
    import("./components/AppHome/Pages/SettingsConditionsPage")
);

export const App = () => {
    const showPopup = useSelector((state) => state.misc.showPopup);
    const user = useSelector((state) => state.auth.user);
    const offers = useSelector((state) => state.offers.items);
    const notificationsChange = useSelector((state) => state.notification.setRead);
    const [notification, setNotification] = useState({ title: "", body: "", clickAction: "" });
    const dispatch = useDispatch();

    const showPosition = (position) =>
        setCenterCoords({ lng: position.coords.longitude, lat: position.coords.latitude });
    const handleError = () => setCenterCoords({ lng: 2.2, lat: 46.7 });

    const [centerCoords, setCenterCoords] = useState(function getInitialState() {
        if (navigator.geolocation)
            return navigator.geolocation.getCurrentPosition(showPosition, handleError);
        else return { lng: 2, lat: 47 };
    });

    useEffect(() => {
        console.log("Last upload: 21/6");
        console.log("Time now: " + new Date());
    }, []);

    useEffect(() => {
        const location = {
            avatarId: user?.avatar?.id,
            geolocation: centerCoords?.lat + ", " + centerCoords?.lng,
        };
        centerCoords && user && dispatch(userActions.setUserLocation(location));
    }, [user, centerCoords, notification, notificationsChange, offers]);

    useEffect(() => {
        notification.title && dispatch(miscActions.showPopup("notification", notification));
    }, [notification]);

    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
                clickAction: payload.notification.click_action,
                type: payload.data.type,
            });
            console.log(payload);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/reset-password" component={ForgotPassword} />
                <PrivateRoute path="/home/map" component={MapPage} />
                <PrivateRoute path="/home/list" component={MapListItemPage} />
                <PrivateRoute path="/history" component={HistoryPage} />
                <PrivateRoute path="/stats" component={StatsPage} />
                <PrivateRoute exact path="/settings" component={SettingsPage} />
                <PrivateRoute path="/settings/preferences" component={SettingsPrefPage} />
                <PrivateRoute path="/settings/security" component={SettingsSecurityPage} />
                <PrivateRoute path="/settings/conditions" component={SettingsConditionsPage} />
                <PrivateRoute path="/notifications" component={NotificationsPage} />
                <PrivateRoute path="/offer/:id" component={OfferPage} />
            </Switch>
            {showPopup && <NoticePopup />}
        </Suspense>
    );
};

export default App;
