export const offerConstants = {
    GETALL_REQUEST: "OFFER_GETALL_REQUEST",
    GETALL_SUCCESS: "OFFER_GETALL_SUCCESS",
    GETALL_FAILURE: "OFFER_GETALL_FAILURE",

    GETALLLIST_REQUEST: "OFFER_GETALLLIST_REQUEST",
    GETALLLIST_SUCCESS: "OFFER_GETALLLIST_SUCCESS",
    GETALLLIST_FAILURE: "OFFER_GETALLLIST_FAILURE",

    GETSINGLE_REQUEST: "OFFER_GETSINGLE_REQUEST",
    GETSINGLE_SUCCESS: "OFFER_GETSINGLE_SUCCESS",
    GETSINGLE_FAILURE: "OFFER_GETSINGLE_FAILURE",

    TAKELEAD_REQUEST: "OFFER_TAKELEAD_REQUEST",
    TAKELEAD_SUCCESS: "OFFER_TAKELEAD_SUCCESS",
    TAKELEAD_FAILURE: "OFFER_TAKELEAD_FAILURE",

    ACCEPT_TAKELEAD_REQUEST: "OFFER_ACCEPT_TAKELEAD_REQUEST",
    ACCEPT_TAKELEAD_SUCCESS: "OFFER_ACCEPT_TAKELEAD_SUCCESS",
    ACCEPT_TAKELEAD_FAILURE: "OFFER_ACCEPT_TAKELEAD_FAILURE",

    DELETE_REQUEST: "OFFER_DELETE_REQUEST",
    DELETE_SUCCESS: "OFFER_DELETE_SUCCESS",
    DELETE_FAILURE: "OFFER_DELETE_FAILURE",
};
