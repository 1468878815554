import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
// import { offerActions } from "../../../helpers/actions/offer.actions";
import { miscActions } from "../../../helpers/actions/misc.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

// const ButtonElement = () => {
//     return <button className="btn btn-regular-primary mt-3">Continuer</button>;
// }; // TODO: might make a component for each button type needed so I can link straight to page redirects
// const NotificationLead = () => {
//     const data = useSelector((state) => state.misc.data);
//     const dispatch = useDispatch();
//     const closePopup = () => dispatch(miscActions.hidePopup());

//     return (
//         <Link className="btn btn-regular-primary mt-3" to={data.clickAction} onClick={closePopup}>
//             Continuer
//         </Link>
//     );
// };

const NoticePopupInner = () => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [button, setButton] = useState("");

    const dispatch = useDispatch();
    const reason = useSelector((state) => state.misc.popupReason);
    const data = useSelector((state) => state.misc.data);
    const closeAndNoBackground = reason === "updateCGU";
    const history = createBrowserHistory();

    useEffect(() => {
        switch (reason) {
            case "acceptLead":
                setTitle("Merci");
                setText("De nous accorder votre confiance");
                setButton("Continuer");
                // setButton(<ButtonElement/>) TODO: can set button as a component to show
                break;
            case "evaluateLead":
                setTitle("Merci");
                setText("Pour votre contribution a Free Pro Partners");
                setButton("Continuer");
                break;
            case "forgotPwd":
                setTitle("Reinitialisation du mot de passe");
                setText("Un nouveau mot de passe a ete envoye a votre adresse email");
                setButton("Continuer");
                break;
            case "changePwd":
                setTitle("Changement du mot de passe");
                setText("Votre mot de passe a ete change avec success");
                setButton("Continuer");
                break;
            case "updateCGU":
                setTitle("");
                setText("Mise a jour des conditions generales d'utilisation");
                setButton("Lire");
                break;
            case "server":
                setTitle("Internal server error");
                setText("The servers are currently not responding. Please try again later.");
                setButton("Okay");
                break;
            case "notification":
                setTitle(`Nouveau ${data.type === "0" ? "lead" : "enquete"}`);
                setText(data?.body);
                setButton("Continuer");
                // setButton(<NotificationLead />);
                break;
            case "badNotification":
                setTitle("Sorry. Too slow");
                setText("This lead has already been taken");
                setButton("Continuer");
                // setButton(<NotificationLead />);
                break;
            case "updatedSettings":
                setTitle("Preferences changed");
                setText("Your preferences were successfully changed");
                setButton("Continuer");
                // setButton(<NotificationLead />);
                break;
            default:
                setTitle("");
                setText("Erreur");
                setButton("Continuer");
                break;
        }
    }, [reason]);

    const closeAndReload = () => {
        dispatch(miscActions.hidePopup());
        window.location.reload();
    };

    const handleUpdateCGU = () => {
        dispatch(miscActions.hidePopup());
        window.location.reload();
    };

    const closePopup = () => dispatch(miscActions.hidePopup());

    const serverDown = () => {
        dispatch(miscActions.hidePopup());
        history.push("/");
        window.location.reload();
    };

    return (
        <div
            className={
                "notice-popup" + (closeAndNoBackground ? " notice-popup--no-background" : "")
            }>
            <div className="notice-popup-inner">
                {title && <h6>{title}</h6>}
                {closeAndNoBackground && (
                    <button
                        type="button"
                        className="notice-popup-close"
                        onClick={closePopup}
                        aria-label="Close">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                )}
                <span className="small">{text}</span>
                <button
                    className="btn btn-regular-primary mt-3"
                    onClick={
                        reason === "acceptLead" || reason === "evaluateLead"
                            ? closeAndReload
                            : reason === "updateCGU"
                            ? handleUpdateCGU
                            : reason === "server"
                            ? serverDown
                            : closePopup
                    }>
                    {button}
                </button>
            </div>
        </div>
    );
};

export default NoticePopupInner;
