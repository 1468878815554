export const userConstants = {
    REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    LOGOUT: "USERS_LOGOUT",

    GETALL_REQUEST: "USERS_GETALL_REQUEST",
    GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
    GETALL_FAILURE: "USERS_GETALL_FAILURE",

    GETSINGLE_REQUEST: "USERS_GETSINGLE_REQUEST",
    GETSINGLE_SUCCESS: "USERS_GETSINGLE_SUCCESS",
    GETSINGLE_FAILURE: "USERS_GETSINGLE_FAILURE",

    DELETE_REQUEST: "USERS_DELETE_REQUEST",
    DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
    DELETE_FAILURE: "USERS_DELETE_FAILURE",

    SENDEVAL_REQUEST: "USERS_SENDEVAL_REQUEST",
    SENDEVAL_SUCCESS: "USERS_SENDEVAL_SUCCESS",
    SENDEVAL_FAILURE: "USERS_SENDEVAL_FAILURE",

    GETAVATAR_REQUEST: "USERS_GETAVATAR_REQUEST",
    GETAVATAR_SUCCESS: "USERS_GETAVATAR_SUCCESS",
    GETAVATAR_FAILURE: "USERS_GETAVATAR_FAILURE",

    SETLOCATION_REQUEST: "USERS_SETLOCATION_REQUEST",
    SETLOCATION_SUCCESS: "USERS_SETLOCATION_SUCCESS",
    SETLOCATION_FAILURE: "USERS_SETLOCATION_FAILURE",

    UPDATESETTINGS_REQUEST: "USERS_UPDATESETTINGS_REQUEST",
    UPDATESETTINGS_SUCCESS: "USERS_UPDATESETTINGS_SUCCESS",
    UPDATESETTINGS_FAILURE: "USERS_UPDATESETTINGS_FAILURE",

    VERIFYPASSWORD_REQUEST: "USERS_VERIFYPASSWORD_REQUEST",
    VERIFYPASSWORD_SUCCESS: "USERS_VERIFYPASSWORD_SUCCESS",
    VERIFYPASSWORD_FAILURE: "USERS_VERIFYPASSWORD_FAILURE",

    CHANGEPASSWORD_REQUEST: "USERS_CHANGEPASSWORD_REQUEST",
    CHANGEPASSWORD_SUCCESS: "USERS_CHANGEPASSWORD_SUCCESS",
    CHANGEPASSWORD_FAILURE: "USERS_CHANGEPASSWORD_FAILURE",

    GETSTATS_REQUEST: "USERS_GETSTATS_REQUEST",
    GETSTATS_SUCCESS: "USERS_GETSTATS_SUCCESS",
    GETSTATS_FAILURE: "USERS_GETSTATS_FAILURE",

    GETHISTORY_REQUEST: "USERS_GETHISTORY_REQUEST",
    GETHISTORY_SUCCESS: "USERS_GETHISTORY_SUCCESS",
    GETHISTORY_FAILURE: "USERS_GETHISTORY_FAILURE",

    CLEARVERIFYFAILED: "USERS_CLEARVERIFYFAILED",
};
