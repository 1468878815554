import { combineReducers } from "redux";
import { alert } from "./alert.reducer";
import { auth } from "./auth.reducer";
import { misc } from "./misc.reducer";
import { register } from "./register.reducer";
import { users } from "./users.reducer";
import { offers } from "./offer.reducer";
import { notification } from "./notification.reducer";

export default combineReducers({
    alert,
    auth,
    register,
    users,
    offers,
    misc,
    notification,
});
