export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem("user"));

    // if (user && user.token) {
    return {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "AAAAAAAAAAAAAAAA",
    };
    // return { Authorization: "Bearer " + user.token };
    //  else
    //     return {};
    //
}
