import { offerConstants } from "../constants/offer.constants";

export function offers(state = {}, action) {
    switch (action.type) {
        //
        // GET ALL
        //
        case offerConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case offerConstants.GETALL_SUCCESS:
            return {
                ...state.item,
                items: action.offers,
            };
        case offerConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET ALL IN THE LIST
        //
        case offerConstants.GETALLLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case offerConstants.GETALLLIST_SUCCESS:
            return {
                ...state.item,
                items: action.offers,
            };
        case offerConstants.GETALLLIST_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET SINGLE
        //
        case offerConstants.GETSINGLE_REQUEST:
            return {
                ...state,
                item: {},
                loadingSingle: true,
            };
        case offerConstants.GETSINGLE_SUCCESS:
            return {
                ...state,
                loadingSingle: false,
                item: action.offer,
            };
        case offerConstants.GETSINGLE_FAILURE:
            return {
                error: action.error,
            };
        //
        // TAKE LEAD
        //
        case offerConstants.TAKELEAD_REQUEST:
            return {
                ...state,
                loadingTakeLead: true,
            };
        case offerConstants.TAKELEAD_SUCCESS:
            return {
                ...state,
                loadingTakeLead: false,
            };
        case offerConstants.TAKELEAD_FAILURE:
            return {
                error: action.error,
            };
        //
        // DELETE
        //
        case offerConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map((user) =>
                    user.id === action.id ? { ...user, deleting: true } : user
                ),
            };
        case offerConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter((user) => user.id !== action.id),
            };
        case offerConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                }),
            };
        default:
            return state;
    }
}
