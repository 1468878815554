export const notificationConstants = {
    ADD_TOKEN: "NOTIFICATiON_ADD_TOKEN",
    // ERROR: "NOTIFICATiON_",
    // CLEAR: "NOTIFICATiON_",
    GETALL_REQUEST: "NOTIFICATION_GETALL_REQUEST",
    GETALL_SUCCESS: "NOTIFICATION_GETALL_SUCCESS",
    GETALL_FAILURE: "NOTIFICATION_GETALL_FAILURE",

    SETREAD_REQUEST: "NOTIFICATION_SETREAD_REQUEST",
    SETREAD_SUCCESS: "NOTIFICATION_SETREAD_SUCCESS",
    SETREAD_FAILURE: "NOTIFICATION_SETREAD_FAILURE",

    GETOFFERSTATUS_REQUEST: "NOTIFICATION_GETOFFERSTATUS_REQUEST",
    GETOFFERSTATUS_SUCCESS: "NOTIFICATION_GETOFFERSTATUS_SUCCESS",
    GETOFFERSTATUS_FAILURE: "NOTIFICATION_GETOFFERSTATUS_FAILURE",
};
