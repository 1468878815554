import { userConstants } from "../constants/user.constants";
import { userService } from "../services/user.services";
import { alertActions } from "./alert.actions";
import { miscActions } from "./misc.actions";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export const userActions = {
    login,
    logout,
    register,
    terms,
    validatePhone,
    getAll,
    getSingle,
    sendEvaluation,
    getAvatar,
    setUserLocation,
    updateUserSettings,
    verifyPassword,
    changePassword,
    clearVerifyFailed,
    getStats,
    getHistory,
    // delete: _delete,
};

function login(passport, from) {
    return (dispatch) => {
        dispatch(request({ username: passport.username }));

        userService.login(passport).then(
            (user) => {
                dispatch(success(user));
                history.push("/home/map");
                window.location.reload();
            },
            (error) => {
                dispatch(failure(error.message.toString()));
                dispatch(alertActions.error(error.message.toString(), error.status));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return (dispatch) => {
        dispatch(request(user));

        userService.register(user).then(
            (user) => {
                dispatch(success());
                history.push("/termsandconditions");
                window.location.reload();
                dispatch(alertActions.success("Registration successful"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function validatePhone() {
    return (dispatch) => {
        dispatch(request());

        userService.validatePhone().then(
            () => {
                dispatch(success());
                history.push("/home/map");
                window.location.reload();
                dispatch(alertActions.success("Validation successful"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: userConstants.REGISTER_REQUEST };
    }
    function success() {
        return { type: userConstants.REGISTER_SUCCESS };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function terms(values) {
    history.push("/success/account");
    window.location.reload();
}

function getAll() {
    return (dispatch) => {
        dispatch(request());

        userService.getAll().then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function getSingle(id) {
    return (dispatch) => {
        dispatch(request());

        userService.getSingle(id).then(
            (user) => dispatch(success(user)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETSINGLE_REQUEST };
    }
    function success(user) {
        return { type: userConstants.GETSINGLE_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.GETSINGLE_FAILURE, error };
    }
}

function sendEvaluation(data) {
    return (dispatch) => {
        dispatch(request());

        userService.sendEvaluation(data).then(
            () => {
                dispatch(success());
                dispatch(miscActions.showPopup("evaluateLead"));
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.SENDEVAL_REQUEST };
    }
    function success() {
        return { type: userConstants.SENDEVAL_SUCCESS };
    }
    function failure(error) {
        return { type: userConstants.SENDEVAL_FAILURE, error };
    }
}

function getAvatar(id) {
    return (dispatch) => {
        dispatch(request());

        userService.getAvatar(id).then(
            (avatar) => dispatch(success(avatar)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETAVATAR_REQUEST };
    }
    function success(avatar) {
        return { type: userConstants.GETAVATAR_SUCCESS, avatar };
    }
    function failure(error) {
        return { type: userConstants.GETAVATAR_FAILURE, error };
    }
}

function setUserLocation(location) {
    return (dispatch) => {
        dispatch(request(location.geolocation));

        userService.setUserLocation(location).then(
            (data) => dispatch(success(data)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request(location) {
        return { type: userConstants.SETLOCATION_REQUEST, location };
    }
    function success(data) {
        return { type: userConstants.SETLOCATION_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.SETLOCATION_FAILURE, error };
    }
}

function updateUserSettings(settings) {
    return (dispatch) => {
        dispatch(request());

        userService.updateUserSettings(settings).then(
            (data) => {
                dispatch(success(data));
                localStorage.setItem("preferences", JSON.stringify(settings));
                dispatch(miscActions.showPopup("updatedSettings"));
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.UPDATESETTINGS_REQUEST };
    }
    function success(data) {
        return { type: userConstants.UPDATESETTINGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.UPDATESETTINGS_FAILURE, error };
    }
}

function verifyPassword(verifyPassport, changePassport) {
    return (dispatch) => {
        dispatch(request());

        userService.verifyPassword(verifyPassport).then(
            (data) => {
                dispatch(success(data));
                data === true && dispatch(changePassword(changePassport));
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.VERIFYPASSWORD_REQUEST };
    }
    function success(data) {
        return { type: userConstants.VERIFYPASSWORD_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.VERIFYPASSWORD_FAILURE, error };
    }
}

function changePassword(passport) {
    return (dispatch) => {
        dispatch(request());

        userService.changePassword(passport).then(
            (data) => {
                dispatch(success(data));
                dispatch(miscActions.showPopup("changePwd"));
            },
            (error) => dispatch(failure(JSON.stringify(error)))
        );
    };

    function request() {
        return { type: userConstants.CHANGEPASSWORD_REQUEST };
    }
    function success(data) {
        return { type: userConstants.CHANGEPASSWORD_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.CHANGEPASSWORD_FAILURE, error };
    }
}

function getStats(avatarId) {
    return (dispatch) => {
        dispatch(request());

        userService.getStats(avatarId).then(
            (data) => dispatch(success(data)),
            (error) => dispatch(failure(JSON.stringify(error)))
        );
    };

    function request() {
        return { type: userConstants.GETSTATS_REQUEST };
    }
    function success(data) {
        return { type: userConstants.GETSTATS_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.GETSTATS_FAILURE, error };
    }
}

function getHistory(avatarId) {
    return (dispatch) => {
        dispatch(request());

        userService.getHistory(avatarId).then(
            (data) => dispatch(success(data)),
            (error) => dispatch(failure(JSON.stringify(error)))
        );
    };

    function request() {
        return { type: userConstants.GETHISTORY_REQUEST };
    }
    function success(data) {
        return { type: userConstants.GETHISTORY_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.GETHISTORY_FAILURE, error };
    }
}

function clearVerifyFailed() {
    return { type: userConstants.CLEARVERIFYFAILED };
}
