import { notificationConstants } from "../constants/notification.constants";

export function notification(state = {}, action) {
    switch (action.type) {
        //
        // ADD TOKEN
        //
        case notificationConstants.ADD_TOKEN:
            return {
                token: action.token,
            };
        //
        // GET ALL
        //
        case notificationConstants.GETALL_REQUEST:
            return {
                loadingNotifications: true,
            };
        case notificationConstants.GETALL_SUCCESS:
            return {
                loadingNotifications: false,
                notifications: action.notifications,
            };
        case notificationConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        //
        // SET NOTIFICATION TO READ
        //
        case notificationConstants.SETREAD_REQUEST:
            return {
                loadingSetRead: true,
            };
        case notificationConstants.SETREAD_SUCCESS:
            return {
                loadingSetRead: false,
                setRead: action.data,
            };
        case notificationConstants.SETREAD_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET OFFEFR STATUS FOR NOTIFICATION
        //
        case notificationConstants.GETOFFERSTATUS_REQUEST:
            return {
                loadingOfferStatus: true,
            };
        case notificationConstants.GETOFFERSTATUS_SUCCESS:
            return {
                loadingOfferStatus: false,
                offerStatus: action.offerStatus,
            };
        case notificationConstants.GETOFFERSTATUS_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
