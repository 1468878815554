import { userConstants } from "../constants/user.constants";

export function users(state = {}, action) {
    switch (action.type) {
        //
        // GET ALL
        //
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state.item,
                items: action.users,
            };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET SINGLE
        //
        case userConstants.GETSINGLE_REQUEST:
            return {
                ...state,
                loadingSingle: true,
            };
        case userConstants.GETSINGLE_SUCCESS:
            return {
                ...state,
                loadingSingle: false,
                item: action.user,
            };
        case userConstants.GETSINGLE_FAILURE:
            return {
                error: action.error,
            };
        //
        // SEND EVALUATION OF LEAD
        //
        case userConstants.SENDEVAL_REQUEST:
            return {
                ...state,
                sendingEvaluation: true,
            };
        case userConstants.SENDEVAL_SUCCESS:
            return {
                ...state,
                sendingEvaluation: false,
            };
        case userConstants.SENDEVAL_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET AVATAR DETAILS
        //
        case userConstants.GETAVATAR_REQUEST:
            return {
                ...state,
                loadingAvatar: true,
            };
        case userConstants.GETAVATAR_SUCCESS:
            return {
                ...state,
                loadingAvatar: false,
                avatar: action.avatar,
            };
        case userConstants.GETAVATAR_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET SET USER LOCATION
        //
        case userConstants.SETLOCATION_REQUEST:
            return {
                ...state,
                loadingLocation: true,
                userLocation: action.location,
            };
        case userConstants.SETLOCATION_SUCCESS:
            return {
                ...state,
                loadingLocation: false,
                notifications: action.data,
            };
        case userConstants.SETLOCATION_FAILURE:
            return {
                error: action.error,
            };
        //
        // UPDATE USER SETTINGS
        //
        case userConstants.UPDATESETTINGS_REQUEST:
            return {
                ...state,
                updatingSettings: true,
            };
        case userConstants.UPDATESETTINGS_SUCCESS:
            return {
                ...state,
                updatingSettings: false,
                data: action.data,
            };
        case userConstants.UPDATESETTINGS_FAILURE:
            return {
                error: action.error,
            };
        //
        // VERIFY PASSWORD
        //
        case userConstants.VERIFYPASSWORD_REQUEST:
            return {
                ...state,
                verifyingPassword: true,
            };
        case userConstants.VERIFYPASSWORD_SUCCESS:
            return {
                ...state,
                verifyingPassword: false,
                verified: action.data,
            };
        case userConstants.VERIFYPASSWORD_FAILURE:
            return {
                error: action.error,
            };
        case userConstants.CLEARVERIFYFAILED:
            return {
                ...state,
                verified: undefined,
            };
        //
        // CHANGE PASSWORD
        //
        case userConstants.CHANGEPASSWORD_REQUEST:
            return {
                ...state,
                changed: false,
                changingPassword: true,
            };
        case userConstants.CHANGEPASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
                changed: true,
            };
        case userConstants.CHANGEPASSWORD_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET STATS
        //
        case userConstants.GETSTATS_REQUEST:
            return {
                ...state,
                loadingStats: true,
            };
        case userConstants.GETSTATS_SUCCESS:
            return {
                ...state,
                loadingStats: false,
                stats: action.data,
            };
        case userConstants.GETSTATS_FAILURE:
            return {
                error: action.error,
            };
        //
        // GET HISTORY
        //
        case userConstants.GETHISTORY_REQUEST:
            return {
                ...state,
                loadingHistory: true,
            };
        case userConstants.GETHISTORY_SUCCESS:
            return {
                ...state,
                loadingHistory: false,
                history: action.data,
            };
        case userConstants.GETHISTORY_FAILURE:
            return {
                error: action.error,
            };
        //
        // DELETE
        //
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map((user) =>
                    user.id === action.id ? { ...user, deleting: true } : user
                ),
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter((user) => user.id !== action.id),
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                }),
            };
        default:
            return state;
    }
}
