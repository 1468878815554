import { miscConstants } from "../constants/misc.constants";

export const miscActions = {
    showPopup,
    hidePopup,
    clear,
};

function showPopup(reason, data) {
    return { type: miscConstants.SHOW_POPUP, reason, data };
}

function hidePopup() {
    return { type: miscConstants.HIDE_POPUP };
}

function clear() {
    return { type: miscConstants.CLEAR };
}
