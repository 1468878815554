import { alertConstants } from "../constants/alert.constants";

export const alertActions = {
    success,
    error,
    clear,
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message, status) {
    return { type: alertConstants.ERROR, message, status };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
