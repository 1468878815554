import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./helpers/store/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createBrowserHistory } from "history";

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const history = createBrowserHistory();

var userLanguage = window.navigator.userLanguage || window.navigator.language;

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    supportedLngs: ["fr", "en"],
    lng: localStorage.getItem("language") || userLanguage.split("-")[0] || "fr", // language to use
    fallbackLng: "fr",
    resources: {
        en: {
            common: common_en, // 'common' is our custom namespace
        },
        fr: {
            common: common_fr,
        },
    },
});

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) history.replace(path);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,
    rootElement
);

registerServiceWorker();
